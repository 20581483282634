@font-face {
  font-family: 'icomoon';
  src: url('fonts/icomoon.eot?knsg5m');
  src: url('fonts/icomoon.eot?knsg5m#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?knsg5m') format('truetype'), url('fonts/icomoon.woff?knsg5m') format('woff'),
    url('fonts/icomoon.svg?knsg5m#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-brackets:before {
  content: '\e90c';
}
.icon-youtube:before {
  content: '\e90d';
}
.icon-affiliates:before {
  content: '\e900';
}
.icon-chevron:before {
  content: '\e901';
}
.icon-cpa:before {
  content: '\e902';
}
.icon-earn:before {
  content: '\e903';
}
.icon-html5:before {
  content: '\e904';
}
.icon-hybrid:before {
  content: '\e905';
}
.icon-link:before {
  content: '\e906';
}
.icon-promote:before {
  content: '\e907';
}
.icon-quotes:before {
  content: '\e908';
}
.icon-share:before {
  content: '\e909';
}
.icon-sign_up:before {
  content: '\e90a';
}
.icon-xml:before {
  content: '\e90b';
}
