.confirmButton {
  padding: 0.5em 3.7em !important;
}

.confirm-content {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px;
  box-shadow: 0 -1px 2px 0 rgba(0, 0, 0, 0.05);
}
